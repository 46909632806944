import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contactSelect", "sendEmailButton"]

  connect() {
    this.customerId = this.data.get("customerId");
  }

  updateFormAction() {
    const contactId = this.contactSelectTarget.value;

    if (contactId) {
      this.sendEmailButtonTarget.classList.remove("tw-hidden");
      this.sendEmailButtonTarget.setAttribute("formaction", `/contacts/${contactId}/payment_method_emails?customer_id=${this.customerId}`);
    } else {
      this.sendEmailButtonTarget.classList.add("tw-hidden");
      this.sendEmailButtonTarget.setAttribute("formaction", "#");
    }
  }
}
