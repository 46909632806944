import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "justifiCreditCardForm",
    "creditCardForm",
    "submitButton"
  ];

  static values = {
    address: Object,
    accessToken: String,
    accountId: String,
  };

  submitPaymentMethod(event) {
    event.preventDefault();
    const submitButton = this.submitButtonTarget;

    const { addressValue: address, accessTokenValue: accessToken, accountIdValue: accountId } = this;

    const paymentMethodData = {
      address_line1: address.street_1,
      address_city: address.city,
      address_postal_code: address.postal_code,
      address_country: address.country_alpha2,
    };

    this.justifiCreditCardFormTarget
      .tokenize(accessToken, paymentMethodData, accountId)
      .then((data) => {
        submitButton.textContent = "Creating...";
        submitButton.disabled = true;
        if (data.error) {
          this.notifyError(data.error);
        } else {
          this.appendPaymentMethodId(data.id);
          this.creditCardFormTarget.submit();
        }
      });
  }

  /**
   * Handles error notifications.
   * @param {string} error - The error message to notify.
   */
  notifyError(error) {
    if (typeof Honeybadger === "object") {
      Honeybadger.notify(error);
    } else {
      console.warn(error);
    }
  }

  /**
   * Appends the payment method ID as a hidden input to the payment form.
   * @param {string} paymentMethodId - The ID of the payment method to append.
   */
  appendPaymentMethodId(paymentMethodId) {
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "payment_method_id";
    hiddenInput.value = paymentMethodId;
    this.creditCardFormTarget.appendChild(hiddenInput);
  }
}
