import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"]

  copy() {
    const url = this.linkTarget.href;
    navigator.clipboard.writeText(url).then(() => {
      this.element.querySelector('#send-link-button').textContent = "Link Copied";
    })
  }
}
