/* global Turbo */

import { Controller } from "stimulus"

export default class extends Controller {
  async create(event) {
    this.customerId = event.currentTarget.dataset.customerId;
    this.linkUrl = event.currentTarget.dataset.linkUrl;
    this.paymentMethodUrl = event.currentTarget.dataset.payment_methodUrl;

    const response = await fetch(this.linkUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ customer_id: this.customerId }),
    })

    const { link_token } = await response.json()

    if (typeof Plaid !== 'undefined') {
      const handler = Plaid.create({
        token: link_token,
        onSuccess: this.handleSuccess.bind(this),
        onExit: this.handleExit.bind(this),
      });
      handler.open();
    } else {
      if (typeof Honeybadger === "object") {
        Honeybadger.notify("Plaid is not defined. Unable to create handler.");
      }
    }
  }

  async handleSuccess(public_token, metadata) {
    const loader = document.getElementById('loader');
    loader.classList.remove('tw-hidden');

    try {
      const response = await fetch(this.paymentMethodUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name=\'csrf-token\']').content,
        },
        body: JSON.stringify({
          public_token: public_token,
          metadata: metadata,
          customer_id: this.customerId,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result && result.redirect_url) {
          Turbo.visit(result.redirect_url);
        }
      } else {
        throw new Error(`Server responded with ${response.status}`);
      }
    } catch (error) {
      if (typeof Honeybadger === "object") {
        Honeybadger.notify(error);
      }
    } finally {
      loader.classList.add('tw-hidden');
    }
  }

  handleExit() {
    // No action needed on exit
  }
}
